import Lottie from 'lottie-react';
import logoAnimation from '../../lottie/ar-loading.json';
import styled from 'styled-components';
import {AnimatePresence, motion} from 'framer-motion/dist/framer-motion'

interface MotionELement {
  animate: any,
  exit?: any
}

const ArLoadingLogo: React.FC<MotionELement> = ({animate}) => {

  return (
    <ArLoadingLogoContainer
      exit={{ opacity: 0}}
      animate={animate}
      transition={{ ease: "easeOut", duration: .5 }}
    >
      <LogoItemContainer>
        <Lottie
          autoplay
          loop={true}
          animationData={logoAnimation}
        />
      </LogoItemContainer>
    </ArLoadingLogoContainer>
  );
}

const ArLoadingLogoContainer = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: color.white;

`

const LogoItemContainer = styled.div`
  height: 300px;
  width: 300px;
`

export default ArLoadingLogo;
