import styled, {css} from 'styled-components';
import {StyledButton} from "../../Common/Button/styles";
import BaseLayout from "../../Common/BaseLayout";
import {theme} from "../../../styles/globalStyles"
import {StyledTabs} from "../../Common/Tabs/styles";


export const StyledInspect = styled.aside`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: ${theme.colors.white};
  
  transform: translateX(105%);
  ${props => props.isVisible && css`
    transform: translateX(0);
  `}
  transition: transform 0.4s ease;
`;

export const ScrollWrapper = styled(BaseLayout)`
  position: relative;
  max-height: 100%;
  overflow-y: scroll;
`

export const ButtonWrapper = styled.div`
  position: fixed;
  z-index: 10;
  bottom: 2.4rem;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - var(--page-padding) - var(--page-padding));
  display: flex;
  justify-content: center;
  align-items: center;
  
  ${StyledButton} {
    //Glow 
    &:before {
      content: "";
      position: absolute;
      top: 70%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 97%;
      height: 80%;
      box-shadow: rgb(0 0 0 / 25%) 0px 4px 4px;
      filter: blur(20px);
      background-color: rgb(208, 74, 2);
      z-index: -1;
    }
  }
`

export const ContentWrapper = styled.div`
  margin-top: 3.7rem;
  margin-bottom: 8rem;
  display: grid;
  gap: 2rem;
  
  ${StyledTabs} {
    margin-top: 3rem;
  }
`
