import { FC, useEffect, useState, useRef } from 'react'
import { StyledOrientationInfo, LogoItemContainer } from './styles';
import Lottie from "lottie-react";
import logoAnimation1 from "../../lottie/vertical-orientation.json"

interface OrientationInfo {
}

const OrientationInfo: FC<OrientationInfo> = () => {
  const [visible, setVisible] = useState<boolean>(window.innerHeight < window.innerWidth)

  useEffect(() => {
    window.addEventListener('resize', () => {setVisible(window.innerHeight < window.innerWidth)})
  }, []);


  return visible ? (
      <StyledOrientationInfo id='orientationInfo'>
          <p>Drehe dein Gerät in Portrait-Darstellung</p>
          <LogoItemContainer>
            <Lottie
              autoplay
              loop={true}
              animationData={logoAnimation1}
            />     

        </LogoItemContainer>
    </StyledOrientationInfo>
    ) : <></>
}

export default OrientationInfo