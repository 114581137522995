import styled from 'styled-components'
import {Text} from "../../../styles/typography";
import {StyledButton} from "../../../components/Common/Button/styles";
import {StyledBaseImage} from "../../../components/Common/BaseImage/styles";
import BaseLayout from "../../../components/Common/BaseLayout";

export const StyledStart = styled(BaseLayout)`
  padding: var(--page-padding);
  
  ${StyledBaseImage} {
    margin-top: 0.8rem;
  }
  
  ${Text} {
    margin-top: 0.8rem;
    line-height: 1.5;
  }
  
  ${StyledButton} {
    margin: 3rem auto 0;
  }
`;

export const LinksWrapper = styled.footer`
  margin-top: 6rem;
  display: flex;
  flex-wrap: wrap;
  column-gap: 1.6rem;
  row-gap: 0.4rem;
`;
