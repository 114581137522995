import { useEffect, useRef, FC } from 'react';
import { Canvas } from '@react-three/fiber';
import ArScene from '../arScene';
import { Story } from '../../services/storyService';
import LoadingScreen from './loading-module/loadingScreen';
import TouchContainer from '../touchContainer';
import arService from '../../services/arService';

interface ArCanvas {
  story: Story
}

const ArCanvas: FC<ArCanvas> = ({story}) => {
  const canvas = useRef();

  useEffect(() => {
    if(!canvas.current) return
    arService.getState().start(canvas.current)
    return () => {
      arService.getState().stop()
    }
  }, [canvas])

  return (
    <>
      <LoadingScreen />
      <TouchContainer>
        <Canvas
          ref={canvas}
          onContextMenu={e => {
            e.nativeEvent.preventDefault();
          }}
          style={{ width: '100%', height: '100%', zIndex: -20, position: 'absolute'}}
        >
          <ArScene story={story}/>
        </Canvas>
      </TouchContainer>
    </>
  )
}

export default ArCanvas;