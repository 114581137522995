import { FC, useEffect, useState, useRef } from 'react';
import { Story } from '../../services/storyService';
import { useFrame, useThree } from '@react-three/fiber';
import { Vector2, Raycaster, Object3D, Color} from 'three'
import sceneService from '../../services/sceneService';
import interactionService from '../../services/interactionService';
import debugService from '../../services/debugService';

interface ArScene {
  story: Story
}

const VIEWPORT_CENTER: Vector2 = new Vector2(0, 0)

const ArScene: FC<ArScene> = ({story}) => {
  const sceneRef = useRef<Object3D>()
  const groundRef = useRef<Object3D>()
  const modelRef = useRef<Object3D>()

  const mode = interactionService(state => state.mode)
  const debugEnabled = debugService(state => state.debugEnabled)

  const { set } = useThree()

  const objects = sceneService((state) => state.objects)
  const camera = sceneService(state => state.camera)

  // @ts-ignore
  const { scene } = sceneService.getState()
  const [ customRaycaster ] = useState<Raycaster>(new Raycaster())

  // position and rotate main model to look position
  useFrame(() => {
    const positionModelToView = () => {
      if(!scene || !camera || !groundRef.current || !modelRef.current) return

      customRaycaster.setFromCamera(VIEWPORT_CENTER, camera)

      const intersects = customRaycaster.intersectObject(groundRef.current, true)
  
      if (intersects.length < 1) return
  
      modelRef.current.position.set(intersects[0].point.x, 0.0, intersects[0].point.z);
    }

    const rotateModelToView = () => {
      if(!scene || !camera || !modelRef.current) return

      modelRef.current.rotation.y = Math.atan2( ( camera.position.x - modelRef.current.position.x ), ( camera.position.z - modelRef.current.position.z ) );
    }

    if(mode === "PLACE") {
      rotateModelToView()
      positionModelToView()
    }
  });

  useEffect(() => {
    sceneService.getState().load(story.assets.modelUri)
    return () => {
      sceneService.getState().dispose()
    }
  }, [])

  useEffect(() => {
    if(!camera) return
    set({camera})
  }, [camera])

  // add r3f scene to 8th wall vanilla three scene
  useEffect(() => {
    if(!scene || !sceneRef.current) return
    scene.add(sceneRef.current)
  }, [scene, sceneRef])

  if(!scene || !camera) return <></>

  return (
    <group ref={sceneRef}>
      <ambientLight color={0xffffff} intensity={1.0}/>
      <directionalLight color={0x404040} intensity={20.0} castShadow={true}/>
      {/* ground plane for raycast intersection tests */}
      <mesh ref={groundRef} rotation={[-Math.PI * 0.5, 0.0, 0.0]} receiveShadow={!debugEnabled}>
        <planeBufferGeometry args={[10.0, 10.0, 8.0, 8.0]}/>
        {debugEnabled ? 
          <meshBasicMaterial color={new Color(0xff00ff)} wireframe={true}/> :
          <shadowMaterial opacity={0.2}/>
        }
      </mesh>
      {/* main model group */}
      <group ref={modelRef} scale={[2.0, 2.0, 2.0]}>
        {objects.static.map(object => object)}
        {objects.interactive.map(object => object)}      
      </group>
    </group>
  )
}

export default ArScene;