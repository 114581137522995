import { StyledLogo } from "./styles"
import {FC} from "react";

interface Logo {
  size?: "s" | "m" | "l"
  type?: "color" | "white"
}

const Logo: FC<Logo> = ({size = "m", type= "color"}) => {
  return <StyledLogo
      size={size}
      src={type ==="color" ? "/res/img/PricewaterhouseCoopers_Logo.png" : "/res/img/PWC_Logo_White.png"}
  />
}

export default Logo
