import styled from 'styled-components'
import BaseLayout from "../../../components/Common/BaseLayout";
import {Text} from "../../../styles/typography";

export const StyledShare = styled(BaseLayout)`
 ${Text} {
  margin-top: 2.3rem;
  
  //Safe area if content gets too long 
  margin-bottom: 8rem;
 }
`

export const ButtonWrapper = styled.div`
  position: fixed;
  bottom: var(--page-padding);
  left: var(--page-padding);
  right: var(--page-padding);
  display: flex;
  gap: 1.3rem;
`
