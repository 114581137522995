import { TouchArea } from './styles'
import { FC, ReactNode } from 'react'
import interactionService from '../../services/interactionService';

interface TouchContainer {
  children: Array<FC> | FC | ReactNode
}

const TouchContainer: FC<TouchContainer> = ({ children }) => {
  const handleTouchStart = event => {
    if (interactionService.getState().mode === "INTERACT") return

    interactionService.setState({mode: "INTERACT"})
  };

  return (
    <TouchArea onTouchStart={handleTouchStart}>
      {children}
    </TouchArea>
  );
}

export default TouchContainer;