import {useParams, useNavigate} from 'react-router-dom';
import {Suspense, FC} from 'react';
import storyService, { Story } from '../../../services/storyService';
import initService from '../../../services/initService';
import ArCanvas from '../../../components/arCanvas';
import permissionService from '../../../services/permissionService';
import Inspect from '../../../components/story/inspect';
import ArFooter from '../../../components/arFooter';
import Logo from "../../../components/Common/Logo";
import {LogoWrapper} from "./styles";

interface Ar {
}

const Ar: FC<Ar> = () => {
  const navigate = useNavigate()

  const { storyUrl } = useParams();

  const isInitialized = initService(state => state.initialized)

  if(!isInitialized) return <></>

  const story: Story | null = storyService.getState().getStoryByUrlName(storyUrl)

  if(!story) navigate('/notFound')
  if(!permissionService.getState().permission.acceptCookie) navigate(`/${story.urlName}`)

  // ToDo(Eric / Dennis) Error handle asset load => redirect to 404

  return (
    <>
        <Suspense fallback={null}>
            <ArCanvas story={story}/>
        </Suspense>

        <LogoWrapper to={`/${story.urlName}`}>
            <Logo size="s" type="white" />
        </LogoWrapper>

        <ArFooter story={story}/>

        {story.content.interactives.map(interactive => <Inspect interactive={interactive} /> )}
    </>
  )
};

export default Ar;
