import { useParams, useNavigate } from 'react-router-dom';
import { FC } from 'react';
import { Button } from '../../../components/Common/Button';
import storyService, { Story } from '../../../services/storyService';
import initService from '../../../services/initService';
import { StyledStart, LinksWrapper } from './styles';
import {Link, Text} from "../../../styles/typography";
import BaseImage from "../../../components/Common/BaseImage";
import Tabs from "../../../components/Common/Tabs";

interface Start {
}

const links = [
    {
        text: "Impressum",
        href: "https://www.pwc.de/de/impressum.html"
    },
    {
        text: "Nutzungsbedingungen",
        href: "https://www.pwc.de/de/nutzungsbedingungen.html"
    },
    {
        text: "Datenschutzerklärung",
        href: "https://www.pwc.de/de/datenschutzerklaerung.html"
    }
]

const Start: FC<Start> = () => {
  const navigate = useNavigate()
  const { storyUrl } = useParams();

  const isInitialized = initService(state => state.initialized)
  if(!isInitialized) return <></>

  const story: Story | null = storyService.getState().getStoryByUrlName(storyUrl)
  if(!story) navigate('/notFound')

  return (
    <StyledStart headline={story.content.start.title}>

      <BaseImage {...story.content.start.image} />

      <Text>{story.content.start.text}</Text>

      <Button url={`/ar/${story.urlName}`} text={'Jetzt Starten'} />

      <LinksWrapper>
        {links.map(({href, text}) => <Link href={href} target="_blank" rel="noopener">{text}</Link>)}
      </LinksWrapper>
    </StyledStart>
  );
}

export default Start;
