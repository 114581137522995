import create from 'zustand'

type RequiredInteraction = {
  id: number
  interacted: boolean
}

type InteractionMode = "PLACE" | "INTERACT" | "INSPECT"

type InteractionService = {
  mode: InteractionMode
  selected: number
  required: Array<RequiredInteraction>
  setInteraction: (id: number, interacted?: boolean) => boolean
  registerRequiredInteraction: (id: number) => void
  setSelected: (id: number | null) => void
};

const interactionService = create<InteractionService>((set, get) => {
  return {
    mode: "PLACE",
    selected: null,
    required: new Array<RequiredInteraction>(),
    setInteraction: (id, interacted = true) => {
      const entry: RequiredInteraction = get().required.find(entry => entry.id === id)

      if(!entry) {
        console.error(`interactionService::setInteraction(): No registerd interaction found for id = ${id}`)
        return false
      }

      entry.interacted = interacted

      console.log(`interactionService::setInteraction(): Set interacted for id = ${id} to = ${interacted}`)

      if(interacted)
        set({mode: "INSPECT"})

      set({required: get().required})  

      return true
    },
    registerRequiredInteraction: (id) => {
      get().required.push({id, interacted: false})
      console.log(`interactionService::registerRequiredInteraction(): Registerd interaction for object with id = ${id}`)
    },
    setSelected: (id) => {
      set({selected: id})
    }
  }
})

export default interactionService