import { useParams, useNavigate } from 'react-router-dom';
import { Button } from '../../../components/Common/Button'
import storyService, { Story } from '../../../services/storyService'
import initService from '../../../services/initService'
import {FC} from 'react'
import permissionService from '../../../services/permissionService';
import {ButtonWrapper, StyledShare} from './styles';
import {theme} from "../../../styles/globalStyles";
import {Text} from "../../../styles/typography";

interface Share {
}

// ToDo(Eric / Dennis) Should this page be a overlay component on the ar page?
// => this would prevent a full reload of the 8th wall and three libs when restarting ...
const Share: FC<Share> = () => {
  const navigate = useNavigate()

  const { storyUrl } = useParams();

  const isInitialized = initService(state => state.initialized)
  if(!isInitialized) return <></>

  const story: Story | null = storyService.getState().getStoryByUrlName(storyUrl)
  if(!story) navigate('/notFound')

  if(!permissionService.getState().permission.acceptCookie) navigate(`/${story.urlName}`)

  const arUrl: string = `/ar/${story.urlName}`

  const handleShareClick = () => {
    const shareUrl: string = window.location.origin + '/' + story.urlName

    // @ts-ignore
    if(!navigator.canShare) {
      console.error('Share::handleShareClick(): Failed to share because navigator is not able to.')
      return
    }

    // ToDo(Eric / Dennis) Send custom text based on current story?
    // => get text / wording from jesse
    const shareData = {
      url: shareUrl,
      text: 'Check out this PwC AR experience.',
      title: 'PwC Trust in Transformation AR experience.'
    }

    navigator
      .share(shareData)
      .then(() => {})
      .catch(error => console.error('Share::handleShareClick(): Failed to share with error =', error))
  };

  return (
    <StyledShare headline={story.content.share.title}>
      <Text>{story.content.share.text}</Text>

      <ButtonWrapper>
        <Button iconUrl="/res/img/share.svg" onClick={handleShareClick} backgroundColor={theme.colors.grey} />
        <Button text={'Zur Evaluierung'} />
      </ButtonWrapper>
    </StyledShare>
  )
}

export default Share;
