import create from 'zustand'

type DebugService = {
  debugEnabled: boolean
  init: () => void
};

const debugService = create<DebugService>((set, get) => {
  return {
    debugEnabled: false,
    init: () => {

      const params = new Proxy(new URLSearchParams(window.location.search), {
        // @ts-expect-error
        get: (searchParams, prop) => searchParams.get(prop),
      });

      // @ts-expect-error
      const debugEnabled: boolean = params.debug

      if(debugEnabled === null) return

      set({debugEnabled})
    }
  }
})

export default debugService