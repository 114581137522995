import { StyledNotFound } from './styles';
import BaseImage from "../../components/Common/BaseImage";

const NotFound = () => {
  return (
    <StyledNotFound headline="Sorry, something went wrong...">
        <BaseImage src="/res/img/newWorldNewSkills.png" width={512} height={512} />
    </StyledNotFound>
  );
};

export default NotFound;
