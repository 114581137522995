import sceneService from '../../services/sceneService'

declare let XR8: any;

export default function customPipelineModule(customThree) {
  return {
    name: 'customPipeline',
    onStart: () => {
      const { camera, scene } = customThree.xrScene()

      sceneService.setState({ camera: camera, scene: scene })

      XR8.XrController.updateCameraProjectionMatrix({
        origin: camera.position,
        facing: camera.quaternion,
      });
    },
  };
}
