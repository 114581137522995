import {StyledTabs, TabHeader, IconWrapper, TabContent, Icon} from "./styles";
import {FC, useState} from "react";
import {Headline, Text} from "../../../styles/typography";


type TabSection = {
    iconUrl: string;
    headline: string;
    text: string;
}

export interface Tabs {
    sections: TabSection[]
};

const Tabs: FC<Tabs> = ({sections}) => {
    const [currentSection, setCurrentSection] = useState(0)

    return (
        <StyledTabs>
            <TabHeader>
                {sections.map((section, index) => (
                    <IconWrapper isSelected={index === currentSection} onClick={() => { setCurrentSection(index) } }>
                        <Icon src={section.iconUrl} />
                    </IconWrapper>
                ))}
            </TabHeader>

            <TabContent>
                <Headline>{sections[currentSection].headline}</Headline>
                <Text>{sections[currentSection].text}</Text>
            </TabContent>
        </StyledTabs>
    );
};

export default Tabs;
