import { FC, useEffect, useState } from 'react'
import initService from '../../services/initService'
import { StyledSplashScreen} from './styles'

interface SplashScreen {
}

const SplashScreen: FC<SplashScreen> = () => {
  const initialized = initService(state => state.initialized)

  return !initialized ? (
    <StyledSplashScreen>
      <p>Trust in Transformation Splash Screen Placeholder</p>
    </StyledSplashScreen>
    ) : <></>
}

export default SplashScreen