import styled from 'styled-components';
import {theme} from "../../../styles/globalStyles"

export const StyledPermissionBanner = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 2rem 3rem 2.5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: ${theme.colors.whiteDark};
  border: 1px solid rgba(0, 0, 0, 0.04);
  border-radius: 0.1rem 0.1rem 0 0;
  z-index: 801;
`

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 1.1rem;
`
