import { SkeletonUtils } from "three-stdlib"
import { Object3D, AnimationMixer, Clock } from 'three'
import { useEffect, useState } from 'react'

interface AnimateObject {
  object: Object3D
  animations: any
}

const AnimateObject: Object3D<AnimateObject> = ({object, animations}) => {
  const [skeleton, setSkeleton] = useState<Object3D | null>(null)
  const [animationMixer, setAnimationMixer] = useState<AnimationMixer | null>(null)
  const [animationActions] = useState<Map<string, any>>(new Map<string, any>())
  const [clock] = useState<Clock>(new Clock())

  const loadAnimations = () => {
    animations.forEach(animation => {
      const animationAction: any = animationMixer.clipAction(animation, skeleton)
      // ToDo(Eric) Animation selection and blending
      animationAction.weight = 1.0
      animationAction.play()
      animationActions.set(animation.name, animationAction)
    })
  }

  useEffect(() => {
    object.traverse(child => child.frustumCulled = false)

    // ToDo(Eric) Handle materials and texture color space.
    setSkeleton(SkeletonUtils.clone(object))
  }, [])

  useEffect(() => {
    if(!skeleton) return
    setAnimationMixer(new AnimationMixer(skeleton))
  }, [skeleton])

  useEffect(() => {
    if(!animationMixer) return
    loadAnimations()
  }, [animationMixer])

  const update = () => {
    requestAnimationFrame(update)

    if(!skeleton || !animationMixer) return

    // ToDo(Eric) Add delta time and cancel update after component is unmounted.
    animationMixer.update(animationMixer.timeScale * clock.getDelta())
  }

  // requestAnimationFrame(update)

  return skeleton ? <primitive object={skeleton}/> : <></>
}

export default AnimateObject