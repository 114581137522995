import { FC, useEffect, useState } from 'react'
import initService from '../../services/initService'
import permissionService, { Permission } from '../../services/permissionService'
import PermissionBanner from './permissionBanner'

const COOKIE_TEXT: string = 'Für ein optimales Erlebnis werden Cookies von 1st und 3rd Party verwendet. Siehe Cookies für weitere Informationen. Mit "Ich lehne ab" werden nur funktionale Cookies verwendet. Mit "Ich akzeptiere" bestätigen Sie die Verwendung aller Cookies.'

interface Permissions {
}

const Permissions: FC<Permissions> = () => {
  const isInitialized = initService(state => state.initialized)
  const permission: Permission = permissionService(state => state.permission)
  const { acceptAll, acceptPartial } = permissionService.getState()
  const [enableOverlay, setEnableOverlay] = useState<boolean>(true)

  useEffect(() => {
    let showOverlay: boolean = false
    Object.values(permission).forEach((value: any) => {showOverlay = value ? false : true})
    setEnableOverlay(showOverlay)
  }, [permission])

  if(!isInitialized) return <></>

  return (
    <>
      {Object.keys(permission).map((permissionKey: string) =>
        !permission[permissionKey] && <PermissionBanner key={permissionKey} text={COOKIE_TEXT} onAcceptAll={() => acceptAll(permissionKey)} onAcceptPartial={() => acceptPartial(permissionKey)}/>
      )}
    </>
  )
}

export default Permissions
