import create from 'zustand'
import { buildUrl } from '../../utils/buildUrl';
import { BaseImage } from "../../components/Common/BaseImage";
import { Tabs } from "../../components/Common/Tabs";

export type interactive = {
  id: number
  title: string
  content: {
    type: "tabs" | "headline" | "text" | "image"
    data: BaseImage | Tabs | string
  }[]
}

export type Story = {
  name: string
  urlName: string
  content: {
    start: {
      title: string
      text: string
      image: BaseImage
    },
    interactives: interactive[],
    share: {
      title: string
      text: string
    }
  },
  assets: {
    modelUri: string
    qrUri: string
  }
}

export type Stories = Map<string, Story>

type StoryService = {
  stories: Stories
  init: () => Promise<Stories>
  getStoryByName: (name: string) => Story | null
  getStoryByUrlName: (urlName: string) => Story | null
};

const STORIES_JSON_URI: string = '/res/stories.json'

const storyService = create<StoryService>((set, get) => {
  const loadStories = async (uri): Promise<Stories> => {
    return new Promise<Stories>(async (resolve, reject) => {
      if(!uri) reject('storyService::loadStories(): Invalid uri!')

      const response = await fetch(uri).catch(error => {
        console.error("storyService::loadStories(): Failed to load stories from uri:", uri, " with error =", error)
        reject(`storyService::loadStories(): Failed fetching uri = ${uri}`)
      })

      if(!response) reject('Invalid response!')

      // @ts-expect-error
      const json = await response.json()

      const { stories } : {stories: Array<Story>} = json
      set({stories: new Map<string, Story>(stories.map(story => {
        const urlName = buildUrl(story.name)
        return [urlName, {...story, urlName}]
      }))})

      console.log("storyService::loadStories(): Loaded stories =", get().stories)

      resolve(get().stories)
    })
  }

  return {
    stories: null,
    init: async () => {
      return loadStories(STORIES_JSON_URI)
    },
    getStoryByName: (name) => {
      const story: Story | undefined = Array.from(get().stories.values()).find((story: Story) => story.name === name)

      return !story ? null : story
    },
    getStoryByUrlName: (urlName) => {
      urlName = urlName.trimEnd()

      if(!get().stories?.has(urlName)) return null

      return get().stories.get(urlName)
    }
  }
})

export default storyService
