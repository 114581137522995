import {StyledBaseLayout, BaseLayoutHeadline} from "./styles";
import Logo from "../Logo";
import {FC} from "react";

interface BaseLayout {
    headline?: string
};

const BaseLayout: FC<BaseLayout> = ({headline, children, ...others}) => {
    return (
        <StyledBaseLayout {...others}>
            <Logo size="s" />

            {headline && <BaseLayoutHeadline>{headline}</BaseLayoutHeadline>}

            {children}
        </StyledBaseLayout>
    );
};

export default BaseLayout;
