import { DRACOLoader, GLTFLoader } from "three-stdlib"
import { Object3D, Scene, PerspectiveCamera, MeshBasicMaterial, sRGBEncoding } from 'three'
import create from 'zustand'
import UpgradeObject from "../../components/arScene/upgradeObject"

type Interaction = 'upgrade'

type ObjectType = Interaction | 'animate'

type SceneService = {
  loaded: boolean
  scene: Scene
  camera: PerspectiveCamera
  objects: {
    static: Array<Object3D>
    interactive: Array<Object3D>
  }
  load: (uri: string) => void
  dispose: () => void
}

const sceneService = create<SceneService>((set, get) => {
  const gltfLoader = new GLTFLoader()
  const dracoLoader = new DRACOLoader()

  dracoLoader.setDecoderPath(
    "https://www.gstatic.com/draco/versioned/decoders/1.4.3/"
  )
  gltfLoader.setDRACOLoader(dracoLoader)

  // parse scene objects into r3f components that can be used in the ArScene component
  const parseScene = (scene, animations) => {
    const objects = {
      static: new Array<Object3D>(),
      interactive: new Array<Object3D>()
    }

    const upgradeObjects: Map<number, Array<Object3D>> = new Map<number, Array<Object3D>>()

    scene.traverse((object) => {
      if(object.type !== "Mesh") return

      const { type } : { type: ObjectType } = object.userData

      if(!type) {
        object.material = new MeshBasicMaterial({color: object.material.color, map: object.material.map})

        if(object.material.map)
          object.material.map.encoding = sRGBEncoding

        objects.static.push(<primitive key={object.id} object={object}/>)
        return
      }

      // parse interactive objects into custom r3f components based on type set in blender

      switch(type) {
        case 'upgrade': {
          const { id } = object.userData

          if(!upgradeObjects.has(id))
            upgradeObjects.set(id, new Array<Object3D>())

          upgradeObjects.get(id).push(object)  
          
          break;
        }
        default: {
          console.warn(`sceneService::parse(): Invalid object type!`)
          return;
        }
      }
    })

    upgradeObjects.forEach((entries: Array<Object3D>, id: number) => {
      objects.interactive.push(<UpgradeObject key={id} objects={entries} animations={animations}/>)
    })

    // update state of service and trigger rerender based on hooks

    sceneService.setState({objects, loaded: true})
  }

  return {
    loaded: false,
    scene: null,
    camera: null,
    objects: {
      static: new Array<Object3D>(),
      interactive: new Array<Object3D>()
    },
    load: (uri) => {
      gltfLoader.load(uri,
        ({scene, animations}) => {
          // ToDo(Eric) Find a better way to manage animations and assign them to the correct objects.
          parseScene(scene, animations)
        },
        () => {},
        (error) => {
          console.error(`sceneService::load(): Failed to load scene from = ${uri} with error = ${error}!`)
        }
      )
    },
    dispose: () => {
      // ToDo(Eric) Dispose objects, materials and textures by book.

      set({
        loaded: false,
        scene: null,
        camera: null,
        objects: {
          static: new Array<Object3D>(),
          interactive: new Array<Object3D>()
        }
      })
    }
  }
})

export default sceneService