import styled from "styled-components";

export const StyledBaseImage = styled.div`
    position: relative;
    width: 100%;
    padding-top: calc(var(--ar) * 100%);
    overflow: hidden;
`;

export const Img = styled.img`
    position: absolute;
    top: 0;
    left: 0;
`
