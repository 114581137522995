import { Button } from '../../components/Common/Button';
import storyService, { Stories, Story } from '../../services/storyService';
import initService from '../../services/initService';
import { useNavigate } from 'react-router-dom';
import {StyledLanding, ButtonsWrapper, TopWrapper} from "./styles";

const Landing = () => {
  const navigate = useNavigate()

  const isInitialized = initService(state => state.initialized)
  const stories: Stories = storyService(state => state.stories)

  if(!isInitialized) return <></>

  if(!stories) navigate('/notFound')

  return (
    <StyledLanding headline={"Landing Page"}>

      {stories &&
        <ButtonsWrapper>
          {Array.from(stories.values()).map((story: Story) => <Button url={`/${story.urlName}`} text={story.name}/>)}
        </ButtonsWrapper>
      }
    </StyledLanding>
  );
}

export default Landing;
