import create from 'zustand'

type StorageService = {
  init: () => Promise<void>
  set: (key: string, value: any) => boolean
  get: (key: string) => any | null
}

const storageService = create<StorageService>((set, get) => {
  return {
    init: () => {
      return new Promise((resolve, reject) => {
        if (typeof(Storage) === "undefined")
          reject('storageService::init(): Failed initializing storage service because Storage is no supported on the device!')
        resolve()
      })
    },
    set: (key, value) => {
      if(!key || !value) return false
      window.localStorage.setItem(key, value)
      return true
    },
    get: (key) => {
      const value: any = window.localStorage.getItem(key)
      if(!value) return null
      return value
    }
  }
})

export default storageService