import styled from "styled-components";
import BaseLayout from "../../components/Common/BaseLayout";

export const StyledLanding = styled(BaseLayout)`
`;

export const ButtonsWrapper = styled.div`
  display: grid;
  place-items: center;
  margin-top: 5rem;
  gap: 0.8rem;
  width: 100%;
`
export const TopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
`
