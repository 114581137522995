import { FC, useEffect, useState } from 'react'
import interactionService from '../../services/interactionService';
import { Story } from '../../services/storyService';
import { Button } from '../Common/Button';
import {StyledArFooter, StyledInteractionProgress, StyledPlaceComponent} from './styles';
import {theme} from "../../styles/globalStyles";

interface ArFooter {
  story: Story
}

const ArFooter: FC<ArFooter> = ({story}) => {
  const backUrl: string = `/${story.urlName}`
  const shareUrl: string = `/share/${story.urlName}`
  const mode = interactionService(state => state.mode)
  const required = interactionService(state => state.required)
  const [component, setComponent] = useState(<></>)

  const handlePlaceClick = event => {
    event.preventDefault()
    if (interactionService.getState().mode === "INTERACT") return

    interactionService.setState({mode: "INTERACT"})
  };

  useEffect(() => {
    const finishedRequiredInteractions = required.filter(entry => entry.interacted).length
    const totalRequiredInteractions = required.length

    switch (mode) {
      case "PLACE": {
        setComponent(
            <StyledPlaceComponent>
              <Button url={backUrl} iconUrl={"/res/img/arrow_left.svg"} backgroundColor={theme.colors.grey} />
              <Button onClick={handlePlaceClick} text={'Zum Platzieren antippen'} />
            </StyledPlaceComponent>
        )
        break
      }
      case "INTERACT": {
        if(finishedRequiredInteractions === totalRequiredInteractions) {
          setComponent(<Button url={shareUrl} text={'Geschichte beenden'}/>)
          break
        }
        setComponent(
          <StyledInteractionProgress bold>
            {`${finishedRequiredInteractions} / ${totalRequiredInteractions} Services aktiviert`}
          </StyledInteractionProgress>
        )
        break
      }
      default: {
        setComponent(<></>)
        break
      }
    }

  }, [mode, required])

  return <StyledArFooter>{component}</StyledArFooter>
}

export default ArFooter
