import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";

import Landing from './pages/landing'
import Start from './pages/story/start';
import Ar from './pages/story/ar';
import Share from './pages/story/share';
import NotFound from './pages/notFound';
import initService from "./services/initService";
import Header from "./components/header";
import Permissions from "./components/permissions";
import SplashScreen from "./components/splashScreen";
import DesktopLanding from "./components/desktopLanding";
import OrientationInfo from "./components/orientationInfo";
import storyService, { Story } from "./services/storyService";

const App = () => {
  const isInitialized = initService(state => state.initialized)

  const init = async () => {
    try {
      await initService.getState().init()
      console.log("App::init(): Initialized application.")
    } catch(error) {
      console.error(error)
    }
  }

  useEffect(() => {
    init()
  }, [])

  if(!isInitialized) return <></>

  return (
    <>
      <SplashScreen/>
      <OrientationInfo />
      <Permissions/>

      <Router>
        <Routes>
          <Route path="/notFound" element={<NotFound />}/>
          <Route path="/ar/:storyUrl" element={<Ar />}/>
          <Route path="/share/:storyUrl" element={<Share />}/>
          <Route path="/:storyUrl" element={<Start />}/>
          <Route path="/" element={<Landing />}/>
        </Routes>
      </Router>
    </>
  );
}

export default App;
