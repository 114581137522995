import styled from 'styled-components';

export const StyledSplashScreen = styled.div`
  position: absolute;

  width: 100%;
  height: 100%;

  display: flex;

  flex-direction: column;
  align-content: center;
  
  background-color: white;
  z-index: 1000;
`