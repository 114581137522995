import styled, { css } from 'styled-components';
import {theme} from "../../../styles/globalStyles";

const ButtonStyles = css`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border-radius: 3px;

  width: ${props => props.icon ? "fit-content" : "100%"};
  max-width: 35rem;
  background-color: ${props => props.backgroundColor || theme.colors.orange};
  color: ${props => props.textColor || theme.colors.white};
`

export const StyledButton = styled.button`
  ${ButtonStyles};
`;
