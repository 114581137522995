import create from 'zustand'
import storageService from '../storageService'

export type Permission = {
  acceptCookie: boolean
}

type PermissionService = {
  permission: Permission
  init: () => Promise<Permission>
  acceptAll: (permissionKey: string) => Permission
  acceptPartial: (permissionKey: string) => Permission
}

const permissionService = create<PermissionService>((set, get) => {
  const update = () => {
    const permission: Permission = {
      acceptCookie: storageService.getState().get('acceptCookie')
    }

    console.log('permissionSerive::update(): Updated permissions to =', permission)

    permissionService.setState({permission})
    return permissionService.getState().permission
  }

  return {
    permission: null,
    init: () => {
      return new Promise((resolve) => {
        resolve(update())
      })
    },
    acceptAll: (permissionKey) => {
      storageService.getState().set(permissionKey, 'all')
      return(update())
    },
    acceptPartial: (permissionKey) => {
      storageService.getState().set(permissionKey, 'partial')
      return(update())
    }
  }
})

export default permissionService