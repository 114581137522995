import create from 'zustand'
import storyService, { Story } from '../storyService';
import {LoadingFactory} from '../../components/arCanvas/loading-module/loading-module';
import customThreejsPipelineModule from '../../components/arCanvas/customThreejsPipelineModule';
import customPipelineModule from '../../components/arCanvas/customPipelineModule';
import { loadavg } from 'os';
import debugService from '../debugService';

declare const window: any;
declare let XR8: any;
declare let LandingPage: any;

type ArService = {
  started: boolean
  loading: any
  init: () => void
  start: (canvas) => void
  stop: () => void
}

const arService = create<ArService>((set, get) => {
  const landingPage = () => {
    if(XR8.XrDevice.isDeviceBrowserCompatible()) return

    const pathnameParts: Array<string> = window.location.pathname.split('/')
    let story: Story | null = null

    if(pathnameParts.length > 1) {
      const storyUrl: string = pathnameParts[pathnameParts.length - 1]
      story = storyService.getState().getStoryByUrlName(storyUrl)
    }

    if(!story)
      story = storyService.getState().stories.values().next().value

    if(!story)
      console.error('arService::landingPage(): No fallback story found!')  

    const mediaSrc: string = `${story.assets.modelUri}`
    const url: string = `${window.location.origin}/${story.urlName}`

    XR8.addCameraPipelineModules([
      LandingPage.pipelineModule(),
    ])

    LandingPage.configure({
      mediaSrc,
      logoSrc: '/res/img/PricewaterhouseCoopers_Logo.png',
      promptPrefix: 'Scanne den QR Code oder besuche',
      promptSuffix: 'um die AR Experience zu nutzen',
      textColor: '#000000',
      backgroundBlur: '1',
      backgroundColor: 'linear-gradient(#FFFFFF, #FFFFFF)',
      backgroundSrc: '',
      sceneEnvMap: 'none',
      sceneOrbitIdle: 'spin',
      sceneOrbitInteraction: 'drag',
      url
    })

    XR8.run({ canvas:  window.mainCanvas })
    document.querySelector('.landing8-container').parentElement.setAttribute('id', 'custom-landing-root')
  }
  const load = (canvas) => {
    const customThree = customThreejsPipelineModule()

    XR8.addCameraPipelineModules([
      XR8.XrController.pipelineModule(), // Enables SLAM tracking.
      XR8.GlTextureRenderer.pipelineModule(), // Draws the camera feed.
      get().loading.pipelineModule(), // Manages the loading screen on startup
      customThree, // Custom pipeline modules.
      customPipelineModule(customThree)
      //XRExtras.Stats.pipelineModule()
    ]);

    if (XR8.XrDevice.isDeviceBrowserCompatible()) {
      arService.setState({started: true})
    }

    XR8.XrController.configure({
      enableLighting: false,
      enableWorldPoints: true,
      disableWorldTracking: false,
    });

    XR8.run({ canvas });
  }
  const screenlog = () => {
    window.XRExtras.DebugWebViews.enableLogToScreen()
  }

  return {
    started: false,
    loading: LoadingFactory(),
    init: () => {
      window.XR8 ? landingPage() : window.addEventListener('xrloaded', landingPage)
      if(debugService.getState().debugEnabled)
        window.XRExtras ? screenlog() : window.addEventListener('xrextrasloaded', screenlog)
    },
    start: (canvas) => {
      const startAfterLoad = () => {
        get().loading.showLoading({ onxrloaded: load(canvas) })
      }
      window.XR8 ? startAfterLoad() : window.addEventListener('xrloaded', startAfterLoad)
    },
    stop: () => {
      console.log('arService::stop(): Stopping 8th wall!');
      set({started: false})
      XR8.stop()
      XR8.clearCameraPipelineModules()
    }
  }
})

export default arService