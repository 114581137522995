import { createGlobalStyle } from 'styled-components';
import { reset } from "./reset";

export const theme = {
    colors: {
        black: '#000',
        grey: '#707070',
        greyLight: '#F3F3F3',
        white:'#FFFFFF',
        whiteDark:'#F3F3F3',
        orange: '#D04A02',
        tangerine: '#EB8C00',

        //Old
        red: '#ff0000',
        lightGrey: '#f2f2f2',
        lightGreen: '#90EE90'
    },
    fonts: {
        helvetica: '/res/fonts/helvetica-neue-regular.woff2',
        helveticaBold: '/res/fonts/helvetica-neue-bold.woff2',
        charter: '/res/fonts/CharterITCW05-Regular.woff2',

        brandomGrotesqueBold: '/res/fonts/BrandonGrotesque-Bold.woff2',
        gothamBold: '/res/fonts/Gotham-Bold.woff2'
    }
}

const GlobalStyle = createGlobalStyle`
  ${reset};
  
  :root {
    --page-padding: 3.2rem
  }

  @font-face {
    font-family: "Helvetica";
    font-weight: 500;
    src: url(${theme.fonts.helvetica}) format("woff2");
    font-display:swap
  }

  @font-face {
    font-family: "Helvetica Bold";
    font-weight: 500;
    src: url(${theme.fonts.helveticaBold}) format("woff2");
    font-display:swap
  }

  @font-face {
    font-family: "Charter";
    font-weight: 500;
    src: url(${theme.fonts.charter}) format("woff2");
    font-display:swap
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    overscroll-behavior: none;
    font-size: 62.5%;
    height: -webkit-fill-available;
  }
  html, html a {
    -webkit-text-size-adjust: none;
    text-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.004);
  }
  
  #root {
    overflow: hidden scroll;
    height: -webkit-fill-available;
    scroll-behavior: smooth;
  }
  
  body {
    font-family: Helvetica;
    height: 100%;
    min-height: -webkit-fill-available;
    overflow: hidden;
    background-color: ${theme.colors.white};

    #prompt-box-8w  {
      background-color: white;
      color: #000000;
      outline: none;
      filter: none;
      width: 100%;
      height: 100vw;
      margin-top: 1em;

      #prompt-box-8w-headline {
        font-size: 1.45em;
        text-align: left;
        max-width: 90vw;
        margin: auto;

        color: ${theme.colors.black};
      }

      p {
         font-size: 1em;
         text-align: left;
         max-width: 90vw;
         margin-top: 1em;
         margin-left: auto;
         margin-right: auto;
         color: black;
       
      }
    

      #prompt-button-container-8w {
        max-width: 90vw;
        }

      #button-primary-8w {
      background-color: #fc4b10;
      color: #FFFFFF;
      border-radius: 0px;

      position: absolute;
      margin-top: 2em;
      padding: 0.5em 1em;
      left: 50%;
      transform: translateX(-50%);
    
      }

      #prompt-button-8w {

      }

      #prompt-button-8w:not(:last-child) {
      background-color: #FFFFFF;
      color: #000000;
      border-radius: 0px;
      text-decoration: underline;
      font-size: 0.75em;
      text-align: left;
      margin-left: 1em;
      margin-top: -1em;
      display: inline-block;
      flex: 0 0 0;
      }
    
     
    }


    // #custom-landing-root {
    //   .landing8-container {
    //     .landing8-background{}
    //     .landing8-centered-container{
    //       .landing8-model-viewer-info {
    //         .landing8-prompt-section {
    //           .landing8-qr {}
    //           .landing8-prompt-text {
    //               .landing8-prompt-link {}
    //           }
    //         }
    //       }
    //     }
    //   }

    // }

  }
`;

export default GlobalStyle;
