import { FC } from 'react'
import {StyledPermissionBanner, ButtonWrapper} from './styles'
import {Text} from "../../../styles/typography";
import {Button} from "../../Common/Button";
import {theme} from "../../../styles/globalStyles";

interface PermissionBanner {
  onAcceptAll: Function
  onAcceptPartial: Function
  text: string
}

const PermissionBanner: FC<PermissionBanner> = ({onAcceptAll, onAcceptPartial, text}) => {
  return (
    <StyledPermissionBanner>
      <Text>{text}</Text>

      <ButtonWrapper>
        <Button text="Ich lehne ab" onClick={onAcceptPartial} backgroundColor={theme.colors.grey} />
        <Button text="Ich akzeptiere" onClick={onAcceptAll} />
      </ButtonWrapper>
    </StyledPermissionBanner>
  )
}

export default PermissionBanner
