import { FC } from 'react';
import { Link } from 'react-router-dom';

import { StyledButton } from './styles';
import {Headline} from "../../../styles/typography";

interface Button {
  url?: string
  text?: string
  iconUrl?: string
  onClick?: Function
  color?: string
  backgroundColor?: string
}

export const Button: FC<Button> = ({
  url = "",
  text = "",
  iconUrl,
  onClick,
  color,
  backgroundColor,
}) => {
  if(!text && !iconUrl) return null

  return (
    <StyledButton
        as={url ? Link : "button"}
        to={url}
        onClick={onClick}
        textColor={color}
        backgroundColor={backgroundColor}
        icon={iconUrl}
    >
      {text && <Headline as="span">{text}</Headline>}
      {iconUrl && <img src={iconUrl} />}
    </StyledButton>
  )
}
