import styled from 'styled-components';

export const StyledOrientationInfo = styled.div`
  background-color: white;
  position: fixed;
  height: 100vh;
  width: 100vw;

  z-index: 30;
  /*  Disable scrollbars */
  text-align:center;
`

export const LogoItemContainer = styled.div`
  height: 250px;
  width: 250px;
  background-color: white;
  display: block;
  margin-left: auto;
  margin-right: auto;
  
  `