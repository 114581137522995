import { useEffect, useRef, useState } from 'react'
import { Object3D, Color, Box3, Vector3 } from 'three'
import { CameraControls } from 'three-stdlib'
import interactionService from '../../../services/interactionService'
import sceneService from '../../../services/sceneService'
import AnimateObject from '../animateObject'

interface UpgradeObject {
  objects: Array<Object3D>
  animations: any
}

const UpgradeObject: Object3D<UpgradeObject> = ({objects, animations}) => {
  const handleInteractionClick = (event) => {
    event.stopPropagation()

    if(level === maxLevel || interactionService.getState().mode === "PLACE") return
    if(level === maxLevel - 1 && required) {
      interactionService.getState().setSelected(id)
      interactionService.getState().setInteraction(id)
    }

    setLevel(level + 1)
  }

  const getGroupByLevel = (level: number) => {
    const object: Object3D = objects.filter(object => level === object.userData.level)[0]

    if(!object) {
      console.error(`UpgradeObject::return(): No object for level = ${level} found!`)
      return <></>
    }

    const children: Array<Object3D> = object.children.filter(child => child.userData.type === "animate")
    object.children = []

    const hitboxSize: Vector3 = new Vector3()
    const boundingBox: Box3 = new Box3().setFromObject(object);
    boundingBox.getSize(hitboxSize)

    return (
      <group position={object.position.toArray()}>
        <mesh ref={poi} position={[0.0, 0.25, 0.0]}>
          <circleBufferGeometry args={[0.025, 12]}/>
          <meshBasicMaterial color={new Color(0xffffff)}/>
        </mesh>
        <mesh visible={false} onClick={handleInteractionClick}>
          <boxBufferGeometry args={hitboxSize.toArray()}/>
          <meshBasicMaterial color={new Color(0xff00ff)} wireframe={true}/>
        </mesh>
        <primitive object={object} position={[0.0, 0.0, 0.0]} castShadow={true}>
          {children.map(child => <AnimateObject object={child} animations={animations}/>)}
        </primitive>
      </group>
    )
  }

  const poi = useRef<Object3D>()

  const [id] = useState<number>(objects[0].userData.id)
  const [required] = useState<boolean>(objects[0].userData.required)

  const [level, setLevel] = useState<number>(0)
  const [maxLevel] = useState<number>(objects.length - 1)

  const [group, setGroup] = useState<Object3D | null>(null)
  const camera = sceneService(state => state.camera)

  useEffect(() => {
    if(!required) return
    interactionService.getState().registerRequiredInteraction(id)
  }, [id])

  useEffect(() => {
    setGroup(getGroupByLevel(level))
  }, [level])

  const update = () => {
    requestAnimationFrame(update)

    if(!poi.current || !camera) return

    // ToDo(Eric) Add delta time and cancel update after component is unmounted.
    poi.current.lookAt(camera.position)
  }

  requestAnimationFrame(update)

  return group ? group : <></>
}

export default UpgradeObject