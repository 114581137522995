import { interactive } from '../../../services/storyService'
import { FC } from 'react'
import {StyledInspect, ButtonWrapper, ContentWrapper, ScrollWrapper} from './styles';
import interactionService from '../../../services/interactionService'
import BaseImage from "../../Common/BaseImage";
import {HeadlineXl, Text} from "../../../styles/typography";
import {Button} from "../../Common/Button";
import Tabs from "../../Common/Tabs";

interface Inspect {
  interactive: interactive
}

const Inspect: FC<Inspect> = ({ interactive }) => {
  const { mode, selected } = interactionService(state => ({
    mode: state.mode,
    selected: state.selected
  }))

  const handleUpskillClick = event => {
    event.preventDefault()
    interactionService.getState().setSelected(null)
    interactionService.setState({mode: "INTERACT"})
  }

  if(!interactive.content) return null

  return (
    <StyledInspect isVisible={mode === "INSPECT" && selected === interactive.id}>
        <ScrollWrapper>
            <ContentWrapper>
              {interactive.content.map(({type, data}) => (
                  {
                    headline: <HeadlineXl>{data}</HeadlineXl>,
                    text: <Text>{data}</Text>,
                    // @ts-ignore
                    tabs: <Tabs sections={data}/>,
                    // @ts-ignore
                    image: <BaseImage {...data} />,
                  }[type]
              ))}
            </ContentWrapper>

            <ButtonWrapper>
                <Button text={`${interactive.title} aktivieren`} onClick={handleUpskillClick} />
            </ButtonWrapper>
        </ScrollWrapper>
    </StyledInspect>
  )
}

export default Inspect;
