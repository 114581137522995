
import React, { useEffect, useRef } from 'react';
import { useLoadingStore } from '../../../services/loadingService';
import { LoadingContainer } from './styles';
import ArLoadingLogo from '../../arLoadingLogo';
import LogoDark from '../../Common/8thWallLogo';

const LoadingScreen = () => {
  // @ts-ignore
  const { loadingScreenIsVisible, setRootNode } = useLoadingStore();

  const rootNode = useRef();

  useEffect(() => {
    setRootNode(rootNode.current);
  }, [loadingScreenIsVisible, setRootNode]);

  const content = (
    <LoadingContainer id="loadingContainer" ref={rootNode} className="absolute-fill">
      <div id="loadBackground" className="absolute-fill">
        <div id="loadImageContainer" className="absolute-fill">
          <ArLoadingLogo animate={loadingScreenIsVisible} />
          <LogoDark />
          <img
            className="foreground-image pwc-icon-center"
            src={"/res/img/PricewaterhouseCoopers_Logo.png"}
             >
          </img>
        </div>
      </div>

      <div id="requestingCameraPermissions" className="hidden">
        <img id="requestingCameraIcon" alt="" src="//cdn.8thwall.com/web/img/loading/v2/camera.svg" />
         Kamerazugriff wird für die AR Experience benötigt.
      </div>

      <div id="cameraPermissionsErrorApple" className="absolute-fill hidden">
        {/* <div className="permissionIconIos">
          <img className="foreground-image" alt="" src="//cdn.8thwall.com/web/img/loading/v2/camera.svg" />
        </div> */}
        <div><img className="foreground-image pwc-icon-left" src={"/res/img/PricewaterhouseCoopers_Logo.png"} /> </div>
        <p id="cameraPermissionsErrorAppleMessage">Fehlende Zugriffsberechtigung der Kamera</p>
        <p id="motionPermissionUserPromptErrorMessage">
           Lade die Seite neu und genehmige den Kamerazugriff, um die Anwendung zu nutzen.
        </p>
        <button id="reloadButton" className="main-button"
          onClick={() => {
            window.location.reload();
          }}
        >
          Neuladen
        </button>
      </div>

      <div id="cameraPermissionsErrorAndroid" className="absolute-fill hidden">
        <div className="loading-error-header">Genehmige den Kamerazugriff in den Browsersettings</div>
        <ol className="loading-error-instructions" >
          <li>
            Öffne die Browser Settings oben rechts
          </li>
          <li>Gehe in die Einstellungen</li>
          <li className="chrome-instruction hidden">
            <span>Website-Einstellungen</span>
          </li>
          <li className="chrome-instruction hidden">
            <span>Kamera</span>
          </li>
          <li className="chrome-instruction hidden">
            <span >Blockiert: 'HIER DOMAIN NAMEN'</span>
          </li>
          <li className="chrome-instruction hidden">
            <span>'Blockieren' entfernen</span>
          </li>
          <li className="samsung-instruction hidden">
            <span>Weitere</span>
          </li>
          <li className="samsung-instruction hidden">
            <span>Website Daten verwalten</span>
          </li>
          <li className="samsung-instruction hidden">
            Press and hold
            <br />
            <span className="camera-instruction-block">Hier DOMAIN NAMEN</span>
          </li>
          <li className="samsung-instruction hidden">
            <span >Entfernen</span>
          </li>
          <div >
             Lade die Seite im Anschluss neu
          </div>
          </ol>
        <div >
          <button id="reloadButton" className="main-button"
          onClick={() => {
            window.location.reload();
          }}
        >
          Neuladen
        </button>
        </div>
      </div>

      <div id="microphonePermissionsErrorApple" className="absolute-fill hidden">
        <div className="permissionIconIos">
          <img className="foreground-image" alt="" src="//cdn.8thwall.com/web/img/loading/v2/camera.svg" />
          <img className="foreground-image" alt="" src="//cdn.8thwall.com/web/img/loading/v2/microphone.svg" />
        </div>
        <p id="microphonePermissionsErrorAppleMessage">Reload the page and enable camera + microphone access</p>
        <div className="bottom-message">
          Ensure camera + microphone access is allowed in <span className="wk-app-name"></span> app settings
        </div>
      </div>

      <div id="microphonePermissionsErrorAndroid" className="absolute-fill hidden">
        <div className="permissionIcon">
          <img className="foreground-image" alt="" src="//cdn.8thwall.com/web/img/loading/v2/microphone.svg" />
        </div>
        <div className="loading-error-header">Let's enable your microphone</div>
        <ol className="loading-error-instructions">
          <li>
            Tap the <img className="foreground-image" alt="" src="//cdn.8thwall.com/web/img/loading/v2/dots.svg" /> in
            the top right
          </li>
          <li>Tap Settings</li>
          <li className="chrome-instruction hidden">
            <span className="highlight">Site settings</span>
          </li>
          <li className="chrome-instruction hidden">
            <span className="highlight">Microphone</span>
          </li>
          <li className="chrome-instruction hidden">
            <span className="highlight">Blocked</span>
            <br />
            <span className="microphone-instruction-block ">apps.8thwall.com</span>
          </li>
          <li className="chrome-instruction hidden">
            <span className="microphone-instruction-button">CLEAR & RESET</span>
          </li>
          <li className="chrome-instruction hidden">
            <span className="highlight">Do the same for Camera</span>
          </li>
          <li className="samsung-instruction hidden">
            <span className="highlight">Advanced</span>
          </li>
          <li className="samsung-instruction hidden">
            <span className="highlight">Manage website data</span>
          </li>
          <li className="samsung-instruction hidden">
            Press and hold
            <br />
            <span className="microphone-instruction-block ">apps.8thwall.com</span>
          </li>
          <li className="samsung-instruction hidden">
            <span className="highlight">DELETE</span>
          </li>
        </ol>
        <div className="loading-error-footer">
          <img className="foreground-image" alt="" src="//cdn.8thwall.com/web/img/loading/v2/reload.svg" />
          Then, reload the page for AR!
        </div>
      </div>

      <div id="linkOutViewAndroid" className="absolute-fill hidden">
        <div className="error-text-outer-container">
          <div className="error-text-container error-margin-top-5">
            <img id="app_img" alt="" className="app-header-img unknown" />
            <br />
            {
              //eslint-disable-next-line
              <a id="open_browser_android" className="start-ar-button">
                Start AR
              </a>
            }
            <img
              className="foreground-image poweredby-img"
              alt=""
              src="//cdn.8thwall.com/web/img/almostthere/v2/poweredby-horiz-white-2.svg"
            />
          </div>
        </div>
      </div>

      <div id="copyLinkViewAndroid" className="absolute-fill hidden">
        <div className="error-text-outer-container">
          <div className="error-text-container error-margin-top-5">
            <span id="error_text_header_unknown" className="open-header-unknown">
              <h2>
                Open in Browser
                <br /> to view AR
              </h2>
            </span>
            <img id="app_img" className="app-header-img unknown" alt="" />
            <br />
            <span id="app_link" className="desktop-home-link mobile"></span>
            <button id="copy_link_android" className="copy-link-btn">
              Copy Link
            </button>
            <img
              alt=""
              className="foreground-image poweredby-img"
              src="//cdn.8thwall.com/web/img/almostthere/v2/poweredby-horiz-white-2.svg"
            />
          </div>
        </div>
      </div>

      <div id="deviceMotionErrorApple" className="absolute-fill hidden">
        <div className="loading-error-header">Let's enable your motion sensors</div>
        <ol className="loading-error-instructions">
          <li>
            Open <img src="https://cdn.8thwall.com/web/img/loading/v1/settings-icon-ios.png" alt="" />
            <b>Settings</b>
          </li>
          <li>
            Select <img src="https://cdn.8thwall.com/web/img/loading/v1/safari-icon.png" alt="" />
            <b>Safari</b>
          </li>
          <li>
            Enable <span className="highlight">Motion&nbsp;&amp;&nbsp;Orientation Access</span>
          </li>
          <li>
            Reload the page{' '}
            <img alt="" className="foreground-image" src="//cdn.8thwall.com/web/img/loading/v2/reload.svg" />
          </li>
        </ol>
        <div className="loading-error-footer"></div>
      </div>

      <div id="userPromptError" className="absolute-fill hidden">
        <div><img className="foreground-image pwc-icon-left" src={"/res/img/PricewaterhouseCoopers_Logo.png"} /> </div>
        <p id="explanationHeadlineMessage">Warum werden Zugriffsberechtigungen benötigt?</p>
        <p id="explanationBodyMessage">
          Damit die Anwendung im vollen Umfang genutzt werden kann ist der Zugriff auf die Bewegungsinformation und Kamera des Gerätes notwendig. Dies dient der Orientierung im Raum und ermöglicht es 3D Inhalte im AR Kontext darstellen zu können. 
         </p>
         <p id="explanationBodyMessage">Zur Nutzung der Anwendung ist die Erlaubnis zwingend erforderlich.</p>

        <button id="reloadButton" className="main-button"
          onClick={() => {
            window.location.reload();
          }}
        >
          Weiter
        </button>
      </div>

     
      <div id="motionPermissionsErrorApple" className="absolute-fill hidden">
        <div><img className="foreground-image pwc-icon-left" src={"/res/img/PricewaterhouseCoopers_Logo.png"} /> </div>
        <p id="cameraPermissionsErrorAppleMessage">Fehlende Zugriffsberechtigung für Bewegungsdaten</p>
        <p id="motionPermissionUserPromptErrorMessage">Starte die Anwendung/deinen Browser neu und genehmige den Zugriff auf die Bewegungsdaten, um die Anwendung zu nutzen.</p>
      </div>
    
    
      <div id="cameraSelectionWorldTrackingError" className="permission-error absolute-fill hidden">
        <p>
          <img
            alt=""
            height="75px"
            src="//cdn.8thwall.com/web/img/runtimeerror/v1/computer-voxel.png"
            className="floater"
          />
        </p>
        <div className="error-text-header">Oops, something went wrong!</div>
        <p id="camera_mode_world_tracking_error"></p>
      </div>
      <div id="debug-message"></div>
    </LoadingContainer>
  );

  return loadingScreenIsVisible ? content : null;
  // return content;
};

export default LoadingScreen;


